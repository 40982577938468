<template>
  <div>
    <!-- <this-header /> -->
    <b-overlay
      :show="ShowLog"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <div class="loader" />
        </div>
      </template>

      <validation-observer ref="simplesRules">
        <div class="container-new pd2 pb-5">
          <div class="d-flex">
            <div
              class="box-lt w-50 cursor-pointer"
              :class="[isActive ? 'box-lt__active' : '']"
              @click="toggle(1)"
            >
              <div class="text-center text-black">
                <i class="icon far fa-calendar-alt" /> โพยหวยทั้งหมด
              </div>
            </div>

            <div
              class="box-lt w-50 cursor-pointer"
              :class="[isActive2 ? 'box-lt__active' : '']"
              @click="toggle(2)"
            >
              <div class="text-center text-black">
                <i class="icon far fa-clock" /> โพยหวยยกเลิก
              </div>
            </div>
          </div>

          <!-- List lotto now -->
          <div v-if="isActive">
            <div
              v-for="item in items"
              :key="item.id"
              class="cards card__stake block mt-50"
            >
              <div class="stake-header bg-new-1">
                <div class="title d-flex align-items-center">
                  <i
                    class="fas fa-print fa-2x mr-1"
                    @click="PrintList(item.list_id)"
                  />
                  <span> #เลขที่ </span>
                  <span class="number">{{ item.list_id }}</span>
                </div>
                <div class="status">
                  <span class="user-cancel badge badge-lg badge__light-purple">{{ item.status === 'confirm' ? 'รับแทง' : 'ยกเลิก' }}</span>
                </div>
              </div>
              <router-link
                :to="{ name: 'tanghuay-list-detail', params: { id: item.list_id } }"
              >
                <div class="stake-body">
                  <div class="stake-credit">
                    <h4 class="amount text-pink-2">
                      {{ item.lotto_type }}
                    </h4>
                    <div class="date text-blue">
                      {{ item.CloseDateName }}
                    </div>
                    <div class="time text-blue">
                      {{ Formatt(item.created_at) }}
                    </div>
                  </div>
                  <div class="stake-detail">
                    <div class="d-flex align-items-center">
                      <span class="label text-blue">เดิมพัน</span>
                      <h3 class="mb-0 ml-auto text-black">
                        {{ item.amount }}
                      </h3>
                    </div> <div class="d-flex align-items-center">
                      <span class="label text-blue">ผลได้เสีย</span>
                      <h3 class="mb-0 ml-auto text-danger num-zero">
                        {{
                          item.List.some(
                            el =>
                              el.status_result !== 'lose' &&
                              el.status_result !== 'win',
                          )
                            ? '-'
                            : Commas(
                              item.List.reduce(
                                (acc, ele) =>
                                  acc +
                                  (ele.status_result === 'win'
                                    ? Number(ele.amount) * Number(ele.win_rate)
                                    : 0),
                                0,
                              ) -
                                item.List.reduce(
                                  (acc, ele) => acc + Number(ele.amount),
                                  0,
                                ),
                            )
                        }}
                      </h3>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="GetLottolist()"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>

          <!-- List lotto history -->
          <div v-if="isActive2">
            <router-link
              v-for="item in itemsLog"
              :key="item.id"
              :to="{ name: 'tanghuay-list-detail', params: { id: item.list_id } }"
              class="cards card__stake block mt-50"
            >
              <div class="stake-header bg-new">
                <div class="title">
                  <span class="label">#เลขที่ </span>
                  <span class="number">{{ item.list_id }}</span>
                </div>
                <div
                  v-if="item.status === 'confirm'"
                  class="status"
                >
                  <span class="user-cancel badge badge-lg badge__light-purple">รับแทง</span>
                </div>

                <div
                  v-else
                  class="status"
                >
                  <span class="user-cancel badge badge-lg badge__light-danger">ยกเลิก</span>
                </div>
              </div>
              <div class="stake-body">
                <div class="stake-credit">
                  <h4 class="amount text-pink-2">
                    {{ item.lotto_type }}
                  </h4>
                  <div class="date text-blue">
                    {{ item.CloseDateName }}
                  </div>
                  <div class="time text-blue">
                    {{ Formatt(item.created_at) }}
                  </div>
                </div>
                <div class="stake-detail text-blue">
                  <div class="d-flex align-items-center">
                    <span class="label">เดิมพัน</span>
                    <h3 class="mb-0 ml-auto text-black">
                      {{ item.amount }}
                    </h3>
                  </div> <div class="d-flex align-items-center">
                    <span class="label text-blue">ผลได้เสีย</span>
                    <h3 class="mb-0 ml-auto text-danger num-zero">
                      0
                    </h3>
                  </div>
                </div>
              </div>
            </router-link>

            <b-pagination
              v-model="currentPageLog"
              :total-rows="totalRowsLog"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="GetCancle()"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </validation-observer>

      <ThisFooter />
    </b-overlay>
    <!-- <this-footer /> -->
  </div>
</template>

<script>
import ThisFooter from '@/layouts/component/ThisFooter.vue'

import {
  BOverlay,
  BIconController,
  BPagination,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
// import vSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
// import moment from 'moment-timezone'
// import {
//   required, integer, between, length,
// } from '@validations'
// import ThisHeader from './component/ThisHeader.vue'
// import ThisFooter from './component/ThisFooter.vue'

export default {
  components: {
    // vSelect,
    // BInputGroupPrepend,
    ValidationObserver,
    // BProgress,
    BOverlay,
    BIconController,
    // ThisHeader,
    ThisFooter,
    BPagination,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      isActive: false,
      isActive2: false,
      Status: 'NOW',
      ShowCancle: false,
      ShowList: false,
      ShowLog: false,
      totalRows: 1,
      currentPage: 1,
      items: [],
      totalRowsLog: 1,
      currentPageLog: 1,
      itemsLog: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      pageOptions: [10, 15, 20],
      perPage: 10,
      fields: [
        { key: 'index', label: 'ลำดับที่' },
        { key: 'list_id', label: 'โพยที่' },
        { key: 'remark', label: 'ชื่อลูกค้า' },
        { key: 'lotto_type', label: 'ประเภทหวย' },
        { key: 'CloseDateName', label: 'งวดประจำวันที่' },
        { key: 'amount', label: 'เงินเดิมพัน' },
        // { key: 'dis_remark', label: 'ส่วนลด' },
        { key: 'status', label: 'สถานะ' },
        { key: 'created_at', label: 'เวลาแทง', formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss') },
        { key: 'actions', label: 'Actions' },
      ],
      fieldsLog: [
        { key: 'index', label: 'ลำดับที่' },
        { key: 'list_id', label: 'โพยที่' },
        { key: 'remark', label: 'ชื่อลูกค้า' },
        { key: 'lotto_type', label: 'ประเภทหวย' },
        { key: 'CloseDateName', label: 'งวดประจำวันที่' },
        { key: 'amount', label: 'เงินเดิมพัน' },
        // { key: 'dis_remark', label: 'ส่วนลด' },
        { key: 'status', label: 'สถานะ' },
        {
          key: 'created_at',
          label: 'เวลาแทง',
          formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'actions', label: 'Actions' },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.GetLottolist()
    this.GetCancle()
    this.toggle(1)
  },
  methods: {
    PrintList(listid) {
      window.open(`https://api.hapi-lot.com/api/slip/listid?ListID=${listid}`)
    },
    toggle(val) {
      if (val === 1) {
        this.isActive = true
        this.isActive2 = false
      } else if (val === 2) {
        this.isActive = false
        this.isActive2 = true
      }
    },
    ChangType(Val) {
      this.Status = Val
      this.currentPage = 1
      this.GetData(Val)
      console.log(this.Status)
    },
    Formatt(value) {
      return moment(value).format('DD/MM/YYYY HH:mm:ss')
    },
    GetLottolist() {
      // const index = 0
      this.ShowLog = true
      const params = {
        page: this.currentPage,
        perpage: this.perPage,
        UserToken: localStorage.getItem('UserToken'),
      }
      this.$http
        .post(
          '/member/bill/Member_list',
          params,
        )
        .then(response => {
          const filteredItems = response.data
          this.items = filteredItems.Mes
          this.items.forEach(async (items, index) => {
            // eslint-disable-next-line no-underscore-dangle
            this.items[index].List = await this.GetListDetail(items.list_id)
          })
          this.totalRows = filteredItems.total
          this.ShowLog = false
        })
        .catch(error => {
          console.log(error)
          this.ShowLog = false
        })
    },
    GetCancle() {
      // const index = 0
      this.ShowCancle = true
      const params = {
        page: this.currentPageLog,
        perpage: this.perPage,
        UserToken: localStorage.getItem('UserToken'),
      }
      this.$http
        .post(
          '/member/bill/Member_Cancle',
          params,
        )
        .then(response => {
          const filteredItems = response.data
          this.itemsLog = filteredItems.Mes
          this.totalRowsLog = filteredItems.total
          this.ShowCancle = false
        })
        .catch(error => {
          console.log(error)
          this.ShowCancle = false
        })
    },
    async GetListDetail(ListId) {
      const params = {
        ListId,
      }
      try {
        const { data: response } = await this.$http.get(
          '/member/history/ShowByListId',
          { params },
        )
        this.show = false
        if (response && response.success === true) {
          return response.Mes
        }
        return []
      } catch (err) {
        console.log(err)
        return []
      }
    },
    Commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
